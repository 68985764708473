// This address points to a dummy ERC-20 contract. Replace it with your own smart contracts.
import { local } from "./abis";

const addresses = {
  goldenSmol: local
    ? "0x48b9aC06135A4d0933f9BfdF0dc74d97a658b869"
    : "0x142932151c31dcb9988F8CdF8F76CED34F121cd0",
  goldenSmolFuji: "0x1B3ceaBab246d62CDe54c7c4604f7268335BAEe6",
  goldenSmolStaking: local
    ? "0x4944C6228937F0c3fdd15Db0b9DF9E4E285B2894"
    : "0xC0D602961Bd9bCA03701Aee3Ec6b43aaB07F39Af",
};
export default addresses;
