import styled from "styled-components";

export const Body = styled.div`
  align-items: center;
  color: white;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  justify-content: flex-start;
  background: rgba(0, 0, 0, 0.9);
  box-shadow: 0 0 47px 52px black;
  gap: 50px;
  min-height: 100vh;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
height: 100%;
`;

export const Button = styled.button`
  border: none;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: capitalize;
  background: #101010;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;
  padding: 6px;
  align-content: center;
  text-decoration: none;
  flex-flow: column;
`;


export const Header = styled.header`
  align-items: center;
  background-color: #282c34;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 70px;
`;

export const Image = styled.img`
  pointer-events: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

export const Link = styled.a.attrs({
  target: "_blank",
  rel: "noopener noreferrer",
})`
  color: #61dafb;
  margin-top: 8px;
`;
