import styled from "styled-components";
import DigitalJourneyStudioLogo from "../assets/digital-journey-studio-logo.png";
import React from "react";
import { SocialMedia } from "./SocialMedia";
import { Image } from "./index";

const StyledDigitalJourneyStudio = styled.div`
  font-size: 12px;
  font-weight: 400;
  gap: 6px;
  display: flex;
  flex-flow: row;
  align-items: center;
`;

const StyledDigitalJourneyStudioCopy = styled.div`
  font-size: 12px;
  font-weight: 400;
  opacity: .5;
  display: flex;
  flex-flow: column;
`;

export const StyledSocialIcons = styled.div`
  font-size: 12px;
  font-weight: 400;
`;

const StyledDigitalJourneyStudioWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
`;

export const DigitalJourneyStudio = () => {
  return (
    <StyledDigitalJourneyStudioWrapper>
      <StyledDigitalJourneyStudio>
          <div  style={{height: 35 + 'px'}} className="">
              <Image
                  src={DigitalJourneyStudioLogo}
                  style={{borderRadius: 5+'px'}}
                  alt="Digital Journey Studio Logo"
              />
          </div>

          <StyledDigitalJourneyStudioCopy>
              Digital Journey Studio
              <StyledSocialIcons>
                  <SocialMedia></SocialMedia>
              </StyledSocialIcons>
          </StyledDigitalJourneyStudioCopy>
      </StyledDigitalJourneyStudio>


    </StyledDigitalJourneyStudioWrapper>
  );
};
