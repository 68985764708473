import goldenSmol from "./abis/golden-smol.json";
import goldenSmolStakingFuji from "./abis/golden-smol-staking-fuji.json";
import goldenSmolStaking from "./abis/golden-smol-staking.json";
import goldenSmolFuji from "./abis/golden-smol-fuji.json";

export const local = false;

const abis = {
  goldenSmol: local ? goldenSmolFuji : goldenSmol,
  staking: local ? goldenSmolStakingFuji : goldenSmolStaking
};

export default abis;
