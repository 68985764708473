import "./index.css";
import { Avalanche, AvalancheTestnet, DAppProvider } from "@usedapp/core";
import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { local } from "@my-app/contracts";

const testNetConfig = {
  readOnlyChainId: AvalancheTestnet.chainId,
  readOnlyUrls: {
    [AvalancheTestnet.chainId]: "https://api.avax-test.network/ext/bc/C/rpc"
  },
  networks: [AvalancheTestnet],
  multicallVersion: 2,
};

const mainnetConfig = {
  readOnlyChainId: Avalanche.chainId,
  readOnlyUrls: {
    [Avalanche.chainId]: "https://rpc.ankr.com/avalanche",
  },
  networks: [Avalanche],
  refresh: '50',
};

const config = local ? testNetConfig : mainnetConfig;

// You should replace this url with your own and put it into a .env file
// See all subgraphs: https://thegraph.com/explorer/

ReactDOM.render(
  <React.StrictMode>
    <DAppProvider config={config}>
      <App />
    </DAppProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
