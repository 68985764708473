import { useCall, useCalls } from "@usedapp/core";
import { Contract } from "ethers";
import { abis, addresses } from "@my-app/contracts";
import { range } from "lodash";

const contract = new Contract(addresses.goldenSmol, abis.goldenSmol);

export function useAddressMintedBalance(address) {
  const { value, error } =
    useCall({
      contract, // instance of called contract
      method: "addressMintedBalance", // Method to be called
      args: [address], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }

  return value?.[0];
}

export function useBalanceOf(address) {
  const { value, error } =
    useCall({
      contract, // instance of called contract
      method: "balanceOf", // Method to be called
      args: [address], // Method arguments - address to be checked for balance
    }) ?? {};

  if (error) {
    return undefined;
  }

  return value?.[0];
}

export function useTokenOfOwnerByIndex(address, balanceCount) {
  const calls =
    range(0, balanceCount).map((index) => {
      return {
        contract, // instance of called contract
        method: "tokenOfOwnerByIndex", // Method to be called
        args: [address, index], // Method arguments - address to be checked for balance
      };
    }) ?? [];
  const results = useCalls(calls, {isStatic: true}) ?? [];
  results.forEach((result, idx) => {
    if (result && result.error) {
      console.error(
        `Error encountered calling 'tokenOfOwnerByIndex' on ${calls[idx]?.contract.address}: ${result.error.message}`
      );
    }
  });
  return results.map((result) => result?.value?.[0]);
}

export function useTokenOfOwnerByIndexSingle(address, index) {
  const { value, error } =
  useCall(address && {
    contract, // instance of called contract
    method: "tokenOfOwnerByIndex", // Method to be called
    args: [address, index], // Method arguments - address to be checked for balance
  }) ?? {};
  if (error) {
    console.error(error.message, 'ERROR FROM USETOKEN');
    return undefined;
  }
  return value?.[0];
}

export function useTokenURIS(tokenIds) {
  const calls =
    tokenIds?.map((tokenId) => {
      return {
        contract, // instance of called contract
        method: "tokenURI", // Method to be called
        args: [tokenId], // Method arguments - address to be checked for balance
      };
    }) ?? [];

  const results =
    useCalls(calls, { isStatic: false, refresh: "everyBlock" }) ?? [];
  results.forEach((result, idx) => {
    if (result && result.error) {
      console.error(
        `Error encountered calling 'totalSupply' on ${calls[idx]?.contract.address}: ${result.error.message}`
      );
    }
  });

  return results.map((result) => result?.value?.[0]);
}

export function usePaused() {
  const { value, error } =
    useCall({
      contract, // instance of called contract
      method: "paused", // Method to be called
      args: [], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }

  return value?.[0];
}


export function useTokenURI(tokenId) {
  const { value, error } =
    useCall(tokenId && {
      contract, // instance of called contract
      method: "tokenURI", // Method to be called
      args: [tokenId], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }
  return value?.[0];
}

export function useMaxTokensPerAddress(address) {
  const { value, error } =
    useCall(address && {
      contract, // instance of called contract
      method: "nftPerAddressLimit", // Method to be called
      args: [], // Method arguments - address to be checked for balance
    }) ?? {};
  if (error) {
    console.error(error.message);
    return undefined;
  }

  return value?.[0];
}
