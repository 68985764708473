import React, { useState } from "react";

export const NumberOfTokens = ({
  nTokensCallback,
  alreadyMinted,
  maxTokenPerWallet,
  account,
}) => {
  const [numberOfTokens, setNumberOfTokens] = useState(1);
  const tokensLeftToMint = maxTokenPerWallet - alreadyMinted;
  const increment = () => {
    if (numberOfTokens < tokensLeftToMint) {
      const nTokens = numberOfTokens + 1;
      setNumberOfTokens(nTokens);
      nTokensCallback(nTokens);
    }
  };

  const decrement = () => {
    if (numberOfTokens > 1) {
      const nTokens = numberOfTokens + -1;
      setNumberOfTokens(nTokens);
      nTokensCallback(nTokens);
    }
  };

  const TokensWrapper = () => {
    return (
      <>
        <button onClick={decrement}>-</button>
        <div>
          {numberOfTokens}/{tokensLeftToMint}
        </div>
        <button onClick={increment}>+</button>
      </>
    );
  };

  return (
    <div className="number-of-tokens-wrapper">
      {account ? TokensWrapper() : null}
    </div>
  );
};
