import React, { useEffect, useState } from "react";
import {
  useCall,
  useContractFunction,
  useEtherBalance,
  useEthers,
} from "@usedapp/core";
import {
  useAddressMintedBalance,
  useMaxTokensPerAddress,
} from "../hooks/contract-read.hook";
import { formatEther } from "ethers/lib/utils";
import { BigNumber, Contract } from "ethers";
import { abis, addresses } from "@my-app/contracts";
import { NumberOfTokens } from "./NumberOfTokens";
import { Snackbar } from "@mui/material";
import styled from "styled-components";

const contract = new Contract(addresses.goldenSmol, abis.goldenSmol);

const StyledMaxMintText = styled.div`
  font-size: 14px;
  text-transform: capitalize;
`;

export const Mint = ({ account }) => {
  const [nTokens, setNTokens] = useState(1);
  const [snackBarOpen, setOpenSnackbar] = useState(false);

  const {
    send: safeMint,
    state: { status, errorMessage },
  } = useContractFunction(contract, "safeMint", {
    transactionName: "Mint",
  });

  useEffect(() => {
    if (status === "Exception") {
      setOpenSnackbar(true);
    }
  }, [status]);

  const onTokenChange = (value) => {
    setNTokens(value);
  };

  const { value: contractPrice } =
    useCall({
      contract,
      method: "PRICE",
      args: [], // Method arguments - address to be checked for balance
    }) ?? {};

  const alreadyMintedByAddress = useAddressMintedBalance(account)?.toNumber();

  const nftPerAddressLimit = useMaxTokensPerAddress(account)?.toNumber();

  const mintPriceBigNumber = contractPrice?.[0];
  const price = mintPriceBigNumber;

  function mint() {
    void safeMint(account, nTokens, { value: price.mul(nTokens) })
      .then(console.log)
      .catch(console.log);
  }
  const ethBalance = useEtherBalance(account);

  const hasEnoughBalance = ethBalance?.gt(price.mul(BigNumber.from(nTokens)));

  const ethMintPrice = mintPriceBigNumber
    ? formatEther(price.mul(BigNumber.from(nTokens)))
    : null;

  const PriceLabel = () => {
    return (
      <div className="price-wrapper">
        <span className="price">
          {ethMintPrice} <span className="avax-text">AVAX</span>
        </span>
      </div>
    );
  };
  const { activateBrowserWallet } = useEthers();

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const isMintedOut = alreadyMintedByAddress >= nftPerAddressLimit;
  return (
    <>
      <Snackbar
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={handleClose}
        message={errorMessage}
      ></Snackbar>

      <div className="mint-button-wrapper">
        <div className="mint-button-container">
          <div
            disabled={hasEnoughBalance ? null : "disabled"}
            className={`mint-button ${
              (!hasEnoughBalance && !!account) || isMintedOut
                ? "disabled-mint"
                : ""
            }`}
            onClick={
              account && !isMintedOut && hasEnoughBalance
                ? () => mint()
                : () => activateBrowserWallet()
            }
          >
            {account ? "MINT" : "connect wallet"}
          </div>

          {isMintedOut ? null : (
            <NumberOfTokens
              maxTokenPerWallet={nftPerAddressLimit}
              alreadyMinted={alreadyMintedByAddress}
              nTokensCallback={onTokenChange}
              account={account}
            />
          )}
        </div>

        {isMintedOut ? (
          <StyledMaxMintText>
            Max Mint Per Wallet Reached
          </StyledMaxMintText>
        ) : (
          <div className="general-info-wrapper">
            <PriceLabel />
          </div>
        )}
      </div>
    </>
  );
};
