import WalletButton from "./WalletButton";
import styled from "styled-components";
import { Image } from "./index";
import DigitalJourneyStudioLogo from "../assets/digital-journey-studio-logo.png";
import { DigitalJourneyStudio } from "./DigitalJourneyStudio";
const TopBarConnectWalletStyled = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 10px 9px 0 9px;
`;

const ControlsContainerStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`;

export const TopBar = () => {
  return (
    <div className="top-bar-container">
      <TopBarConnectWalletStyled>
        <DigitalJourneyStudio></DigitalJourneyStudio>

        <ControlsContainerStyled>
          <WalletButton />
          <div style={{ height: 35 + "px", display:'flex' }} className="">
            <Image
              src={DigitalJourneyStudioLogo}
              style={{ borderRadius: 5 + "px" }}
              alt="Digital Journey Studio Logo"
            />
          </div>
        </ControlsContainerStyled>
      </TopBarConnectWalletStyled>
    </div>
  );
};
