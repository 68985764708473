import {useCall} from "@usedapp/core";
import {abis, addresses} from "@my-app/contracts";

import {Contract}from'@ethersproject/contracts';

const stakingContract = new Contract(addresses.goldenSmolStaking, abis.staking);

export function useGetStakedTokens(address) {
    const { value, error } =
    useCall(address && {
        contract: stakingContract, // instance of called contract
        method: "getStakedTokens", // Method to be called
        args: [address]// Method arguments - address to be checked for balance
    }) ?? {};
    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}
