import {
  useTokenOfOwnerByIndexSingle,
  useTokenURI,
  useTokenURIS,
} from "../hooks/contract-read.hook";
import styled from "styled-components";
import { fetchJson } from "ethers/lib/utils";
import React, { memo, useEffect, useRef, useState } from "react";
import ReactTooltip from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import { useContractFunction, useEthers } from "@usedapp/core";
import { InfinitySpin } from "react-loader-spinner";
import { useIsApprovedForAll } from "../hooks/stake.hook";
import { Contract } from "ethers";
import { abis, addresses } from "@my-app/contracts";
import { useGetStakedTokens } from "../hooks/stake-contract.hook";
import { range } from "lodash";
import { Button, Card, Chip, Paper, Skeleton } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const contract = new Contract(addresses.goldenSmol, abis.goldenSmol);
const stakingContract = new Contract(addresses.goldenSmolStaking, abis.staking);
// Hook

function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();
  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.log("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}
const StyledStakeHeaderWrapper = styled.div`
  font-style: italic;
  font-weight: 700;
  font-size: 64px;
  line-height: 139px;
  display: flex;
  align-items: center;
  opacity: 0.5;

  background: linear-gradient(180deg, #4b88ff 0%, #757497 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  text-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
`;
const StyledStakeWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  width: 100%;
`;

const StyledGoldenSmolStakeWrapper = styled.div`
  display: flex;
  flex-flow: row;
  gap: 9px;
  width: 500px;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const StyledGoldenSmol = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  flex-wrap: wrap;
  font-size: 12px;
`;

const GoldenSmolWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 25px;
  flex-wrap: wrap;
  font-size: 12px;
`;

const StyledAttributesWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  font-size: 12px;
  gap: 3px;
`;
const StyledAttribute = styled.div`
  padding-bottom: 9px;
  gap: 3px;
`;

const StyledTraitType = styled.div`
  display: flex;
  letter-spacing: 0.5px;
  flex-flow: column;
  flex-wrap: wrap;
  font-size: 12px;
  opacity: 0.3;
`;

const StyledTraitTypeValue = styled.div`
  display: flex;
  letter-spacing: 1px;
  flex-flow: column;
  flex-wrap: wrap;
  font-size: 16px;
  padding-bottom: 6px;
  border-bottom: 1px solid #4f4f4f;
`;

const fetchGoldenSmol = (tokens) => {
  return Promise.all(
    tokens.map((tokenURI) => {
      const gatewayHost = `https://cloudflare-ipfs.com/ipfs`;
      const metadataIpfsCID = tokenURI?.replace("ipfs://", "");

      const metadataGatewayLink = `${gatewayHost}/${metadataIpfsCID}`;
      return metadataIpfsCID
        ? fetchJson(metadataGatewayLink).then((goldenSmol) => {
            return {
              ...goldenSmol,
              image: `${gatewayHost}/${goldenSmol?.image?.replace(
                "ipfs://",
                ""
              )}`,
            };
          })
        : null;
    })
  );
};

const fetchGoldenSmolSingle = (tokenURI) => {
  const gatewayHost = `https://cloudflare-ipfs.com/ipfs`;
  const metadataIpfsCID = tokenURI?.replace("ipfs://", "");

  const metadataGatewayLink = `${gatewayHost}/${metadataIpfsCID}`;
  return metadataIpfsCID
    ? fetchJson(metadataGatewayLink).then((goldenSmol) => {
        return {
          ...goldenSmol,
          image: `${gatewayHost}/${goldenSmol?.image?.replace("ipfs://", "")}`,
        };
      })
    : null;
};

const TokenLoading = ({ tokens, status }) => {
  const LoadingCard = () => {
    return (
      <Paper elevation={3}>
        <div style={{ display: "flex", flexFlow: "column", gap: 5 }}>
          <Skeleton variant="rectangular" width={125} height={125} />
          <Chip label={status} />

          <LoadingButton style={{ width: "100%" }} loading variant="outlined">
            Staking
          </LoadingButton>
        </div>
      </Paper>
    );
  };

  return (
    tokens?.map(() => {
      return <LoadingCard />;
    }) ?? <LoadingCard />
  );
};

const GoldenSmolTokens = ({
  tokenIndex,
  isApprovedForAll,
  setApprovalForAll,
  stakeSmol,
  unstake,
  account,
  setStakingLoading,
}) => {
  const ownedGoldenSmol = useTokenOfOwnerByIndexSingle(
    account,
    tokenIndex
  )?.toNumber();

  const tokenUri = useTokenURI(ownedGoldenSmol);
  const [smol, setGoldenSmol] = useState(null);

  const stake = () => {
    setStakingLoading(true);
    return stakeSmol(smol?.edition);
  };

  useEffect(() => {
    fetchGoldenSmolSingle(tokenUri)?.then((goldenSmolToken) => {
      setGoldenSmol(goldenSmolToken);
    });
  }, [tokenUri]);

  return (
    <GoldenSmolWrapper>
      {smol ? (
        <StyledGoldenSmol>
          <div>{smol.name}</div>
          <img
            data-html={true}
            data-tip={ReactDOMServer.renderToString(
              <StyledAttributesWrapper>
                {smol?.attributes?.map((attribute) => {
                  return (
                    <StyledAttribute>
                      <StyledTraitType>{attribute.trait_type}</StyledTraitType>
                      <StyledTraitTypeValue>
                        {attribute.value}
                      </StyledTraitTypeValue>
                    </StyledAttribute>
                  );
                })}
              </StyledAttributesWrapper>
            )}
            width={"125px"}
            src={smol.image}
            alt=""
          />
          <ReactTooltip />

          <div style={{ width: "100%" }}>
            {!isApprovedForAll ? (
              <Button
                style={{ width: "100%", background: "grey" }}
                variant={"contained"}
                onClick={() => setApprovalForAll()}
              >
                Approve Staking
              </Button>
            ) : (
              <Button
                sx={{
                  background: "black",
                  borderRadius: 0,
                }}
                color={"secondary"}
                style={{ width: "100%" }}
                variant={"contained"}
                onClick={stake}
              >
                {unstake ? "Unstake" : "Stake"}
              </Button>
            )}
          </div>
        </StyledGoldenSmol>
      ) : (
        <TokenLoading />
      )}
    </GoldenSmolWrapper>
  );
};

const GetStakedGoldenSmol = ({ stakedTokenIDS }) => {
  const [stakedGoldenSmol, setStakedGoldenSmol] = useState(null);
  const [isUnstaking, setIsUnstaking] = useState(false);

  const tokenURIS = useTokenURIS(stakedTokenIDS);

  const urisStringify = JSON.stringify(tokenURIS);

  useEffect(() => {
    fetchGoldenSmol(tokenURIS).then((ownedGoldenSmolTokens) => {
      setStakedGoldenSmol(ownedGoldenSmolTokens);
    });
  }, [urisStringify]);

  const {
    send: unstake,
    state: { status },
  } = useContractFunction(stakingContract, "withdraw", {
    transactionName: "Unstake Golden Smol",
  });

  const unstakeSmol = (tokenId) => {
    setIsUnstaking(true);
    void unstake(tokenId).then(console.log).catch(console.log);
  };

  useEffect(() => {
    if (
      ["Success", "Fail", "Exception"].some((currentStatus) =>
        currentStatus.includes(status)
      )
    ) {
      setIsUnstaking(false);
    } else if (
      ["PendingSignature", "Mining", "Exception"].some((currentStatus) =>
        currentStatus.includes(status)
      )
    ) {
      setIsUnstaking(true);
    }
  }, [isUnstaking, urisStringify, status]);

  return isUnstaking ? (
    <TokenLoading status={status} tokens={stakedGoldenSmol} />
  ) : (
    stakedGoldenSmol?.map((smol) => {
      return (
        <GoldenSmolWrapper>
          {smol ? (
            <StyledGoldenSmol>
              <div>{smol.name}</div>
              <img
                data-html={true}
                data-tip={ReactDOMServer.renderToString(
                  <StyledAttributesWrapper>
                    {smol?.attributes?.map((attribute) => {
                      return (
                        <StyledAttribute>
                          <StyledTraitType>
                            {attribute.trait_type}
                          </StyledTraitType>
                          <StyledTraitTypeValue>
                            {attribute.value}
                          </StyledTraitTypeValue>
                        </StyledAttribute>
                      );
                    })}
                  </StyledAttributesWrapper>
                )}
                width={"125px"}
                src={smol.image}
                alt={smol.name}
              />
              <ReactTooltip />

              <div style={{ width: "100%" }}>
                <Button
                  style={{ width: "100%" }}
                  sx={{
                    background: "black",
                    borderRadius: 0,
                  }}
                  color={"secondary"}
                  variant={"contained"}
                  onClick={() => {
                    return unstakeSmol(smol?.edition);
                  }}
                >
                  Unstake
                </Button>
              </div>
            </StyledGoldenSmol>
          ) : (
            <InfinitySpin width="150" color="#8000FF" />
          )}
        </GoldenSmolWrapper>
      );
    }) ?? <TokenLoading tokens={stakedGoldenSmol} />
  );
};

const MyGoldenSmolTokens = ({ account, currentBalance, ownerTokenIndexes }) => {
  const [staking, setIsStaking] = useState(false);

  useWhyDidYouUpdate("MyGoldenSmolTokens", { account, currentBalance });

  const { send: approveForAll } = useContractFunction(
    contract,
    "setApprovalForAll",
    {
      transactionName: "Approve Staking",
    }
  );

  const {
    send: stake,
    state,
  } = useContractFunction(stakingContract, "stake", {
    transactionName: "Stake Golden Smol",
  });

  const { status } = state;

  const setApprovalForAll = () => {
    void approveForAll(addresses.goldenSmolStaking, true, {})
      .then(console.log)
      .catch(console.log);
  };

  const isApprovedForAll = useIsApprovedForAll(account);

  const stakeSmol = (tokenId) => {
    setIsStaking(true);

    void stake(tokenId)
      .then(console.log)
      .catch((error) => {
        console.error({ error }, "ERROR");
        setIsStaking(false);
      });
  };
  useEffect(() => {
    if (
      ["Success", "Fail", "Exception"].some((currentStatus) =>
        currentStatus.includes(status)
      )
    ) {
      setIsStaking(false);
    } else if (
      ["PendingSignature", "Mining", "Exception"].some((currentStatus) =>
        currentStatus.includes(status)
      )
    ) {
      setIsStaking(true);
    }
  }, [staking, currentBalance, status]);

  const tokens =
    ownerTokenIndexes?.map((index) => {
      return (
        <Card elevation={4}>
          <GoldenSmolTokens
            tokenIndex={index}
            account={account}
            isStaking={staking}
            setStakingLoading={setIsStaking}
            currentBalance={currentBalance}
            stakeSmol={stakeSmol}
            isApprovedForAll={isApprovedForAll}
            setApprovalForAll={setApprovalForAll}
          ></GoldenSmolTokens>
        </Card>
      );
    }) ?? null;

  return staking ? (
    <TokenLoading status={status} tokens={ownerTokenIndexes} />
  ) : (
    tokens
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return prevProps.balanceOf === nextProps.balanceOf;
};

function EmptyGoldenSmolToken() {
  return (
    <div>
      You don't have any <strong>GoldenSmol</strong> tokens in Your wallet
    </div>
  );
}

function NoStakedTokens() {
  return <div>You haven't staked GoldenSmol tokens yet</div>;
}

function StakeCmp({ balanceOf }) {
  const { account } = useEthers();
  const [ownerTokenIndexes, setOwnerTokenIndexes] = useState(null);

  const stakedTokens = useGetStakedTokens(account);
  const stakedTokenIds = stakedTokens?.map((token) =>
    token?.tokenId?.toNumber()
  );
  const ownerIndexesStringified = ownerTokenIndexes?.toString();
  useEffect(() => {
    setOwnerTokenIndexes(range(0, balanceOf));
  }, [balanceOf, ownerIndexesStringified]);

  return (
    <StyledStakeWrapper>
      <StyledStakeHeaderWrapper>STAKE</StyledStakeHeaderWrapper>

      <StyledGoldenSmolStakeWrapper>
        {ownerTokenIndexes?.length ? (
          <MyGoldenSmolTokens
            ownerTokenIndexes={ownerTokenIndexes}
            currentBalance={balanceOf}
            account={account}
          />
        ) : (
          <EmptyGoldenSmolToken></EmptyGoldenSmolToken>
        )}
      </StyledGoldenSmolStakeWrapper>

      <StyledStakeHeaderWrapper>UNSTAKE</StyledStakeHeaderWrapper>

      <StyledGoldenSmolStakeWrapper>
        {stakedTokenIds?.length && stakedTokenIds[0] ? (
          <GetStakedGoldenSmol
            stakedTokenIDS={stakedTokenIds}
          ></GetStakedGoldenSmol>
        ) : (
          <NoStakedTokens></NoStakedTokens>
        )}
      </StyledGoldenSmolStakeWrapper>
    </StyledStakeWrapper>
  );
}
export const Stake = memo(StakeCmp, arePropsEqual);
