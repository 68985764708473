import { Contract } from "ethers";
import { shortenAddress, useCall, useEthers } from "@usedapp/core";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Body, Container, Image, Link } from "./components";
import goldenSmolAlpha from "./assets/smol-show.avif";
import joepegs from "./assets/joepegs-logo.png";
import campfire from "./assets/campfire_logo.png";
import kalaoLogo from "./assets/kalao.jpg";
import styled from "styled-components";
import { abis, addresses } from "@my-app/contracts";
import { useBalanceOf, usePaused } from "./hooks/contract-read.hook";
import { Mint } from "./components/Mint";
import { Stake } from "./components/Stake";
import { TopBar } from "./components/TopBar";
import { SocialMedia } from "./components/SocialMedia";

const StyledGoldenSmolHeader = styled.div`
  font-size: 48px;
  font-style: italic;
  font-weight: 300;
  font-family: "Jost", sans-serif;
`;

const StyledContractAddressWrapper = styled.div`
  font-size: 14px;
  opacity: 0.4;
`;
const StyledGoldenSmolDescription = styled.div`
  font-size: 14px;
  font-style: italic;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  text-transform: capitalize;
  opacity: 0.8;
  display: flex;
  justify-content: flex-start;
`;

const StyledStakeWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

const StyledHeaderWrapper = styled.div`
  display: flex;
  flex-flow: column;
  position: relative;
`;

const StyledContentBodyContainer = styled.div`
  display: flex;
  gap: 50px;
  flex-flow: column;
  position: relative;
  right: 50px;
`;

function App() {
  // Read more about useDapp on https://usedapp.io/
  const { account } = useEthers();
  const { value: supply } =
    useCall({
      contract: new Contract(addresses.goldenSmol, abis.goldenSmol),
      method: "totalSupply",
    }) ?? {};

  const { value: maxSupply } =
    useCall({
      contract: new Contract(addresses.goldenSmol, abis.goldenSmol),
      method: "MAX_SUPPLY",
    }) ?? {};
  const totalMinted = supply?.[0]?.toNumber();
  const MAX_SUPPLY = maxSupply?.[0]?.toNumber();
  const balanceOf = useBalanceOf(account);
  const isPaused = usePaused();
  const PausedInfo = () => {
    return <div className={"paused-contract"}>Contract is Paused</div>;
  };

  return (
    <BrowserRouter>
      <Container>
        <Body>
          <TopBar />
          <StyledHeaderWrapper>
            <StyledContractAddressWrapper>
              <div>
                <Link
                  href={"https://snowtrace.io/address/" + addresses.goldenSmol}
                  underline="hover"
                >
                  GoldenSmol: {shortenAddress(addresses.goldenSmol)}
                </Link>
              </div>
              <div>
                <Link
                  href={"https://snowtrace.io/address/" + addresses.goldenSmolStaking}
                  underline="hover"
                >
                  {" "}
                  Staking Address: {shortenAddress(addresses.goldenSmolStaking)}
                </Link>
              </div>
            </StyledContractAddressWrapper>

            <StyledGoldenSmolHeader>GOLDEN SMOL</StyledGoldenSmolHeader>
            <StyledGoldenSmolDescription>
              development studio
            </StyledGoldenSmolDescription>

            <StyledGoldenSmolDescription>
              decentralized art hub
            </StyledGoldenSmolDescription>
          </StyledHeaderWrapper>
          <StyledContentBodyContainer>
            <div className="mint-count-wrapper">
              {!isPaused && <Mint account={account} />}
              <div className="show-wrapper">
                <div className="show-alpha-wrapper">
                  <Image src={goldenSmolAlpha} />
                </div>
                <div className="sniped-container">
                  <div className="total-minted">
                    {totalMinted} / {MAX_SUPPLY}
                  </div>
                </div>
              </div>
            </div>

            <StyledStakeWrapper>
              {account ? (
                <Stake balanceOf={balanceOf?.toNumber()}></Stake>
              ) : null}
            </StyledStakeWrapper>

            <div className="marketplace-wrapper">
              <div>
                <SocialMedia></SocialMedia>
              </div>
              <div className={"marketplace-logo-container"}>
                <a
                  href={`https://campfire.exchange/collections/${addresses.goldenSmol}`}
                  rel="nofollow noopener noreferrer"
                  target={"_blank"}
                >
                  <Image src={campfire}></Image>
                </a>
              </div>
              <div className={"marketplace-logo-container"}>
                <a
                  target={"_blank"}
                  rel="nofollow noopener noreferrer"
                  href={`https://joepegs.com/collections/${addresses.goldenSmol}`}
                >
                  <Image src={joepegs}></Image>
                </a>
              </div>
              <div className={"marketplace-logo-container kalao"}>
                <a
                  target={"_blank"}
                  rel="nofollow noopener noreferrer"
                  href={`https://marketplace.kalao.io/collection/${addresses.goldenSmol}`}
                >
                  <Image src={kalaoLogo}></Image>
                </a>
              </div>
            </div>
            {isPaused && <PausedInfo />}
          </StyledContentBodyContainer>
        </Body>
      </Container>
    </BrowserRouter>
  );
}

export default App;
