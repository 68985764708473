import {Image} from "./index";
import twitter from "../assets/twitter-logo.png";
import discord from "../assets/discord-logo.png";
import React from "react";

export const SocialMedia = () => {
    return (
        <div className={"social-media-wrapper"}>
            <div className={"twitter-logo social-media-icon"}>
                <a
                    rel="nofollow noopener noreferrer"
                    target={"_blank"}
                    href="https://twitter.com/JourneyByDigits"
                >
                    <Image src={twitter} />
                </a>
            </div>
            <div className={"discord-logo social-media-icon"}>
                <a
                    href="https://discord.gg/juY6nh5GAP"
                    target={"_blank"}
                    rel="nofollow noopener noreferrer"
                >
                    <Image src={discord} />
                </a>
            </div>
        </div>
    );
}
