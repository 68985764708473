import {useCall} from "@usedapp/core";
import {Contract} from "ethers";
import {abis, addresses} from "@my-app/contracts";

const contract = new Contract(addresses.goldenSmol, abis.goldenSmol);

export function useIsApprovedForAll(address) {
    const { value, error } =
    useCall({
        contract, // instance of called contract
        method: "isApprovedForAll", // Method to be called
        args: [address, addresses.goldenSmolStaking] // Method arguments - address to be checked for balance
    }) ?? {};
    if (error) {
        console.error(error.message);
        return undefined;
    }

    return value?.[0];
}
